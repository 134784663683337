<template>
  <div class="row">
    <b-modal :id="formId" :size="formWidth">
      <template #modal-title>
        <div v-if="formState == 'Update'">
          Update Clients #{{ formData.id }}
        </div>
        <div v-if="formState == 'Create'">Create Clients</div>
        <div v-if="formState == 'Pointages'">
          Details de pointages de l'agent
        </div>
      </template>

      <EditAgents v-if="formState == 'Update'" :key="formKey" :actifsData="actifsData" :balisesData="balisesData"
                  :categoriesData="categoriesData" :champsAfficher="champsAfficher" :contratsData="contratsData"
                  :data="formData" :directionsData="directionsData" :echelonsData="echelonsData"
                  :factionsData="factionsData" :fonctionsData="fonctionsData" :gridApi="formGridApi"
                  :matrimonialesData="matrimonialesData" :modalFormId="formId"
                  :nationalitesData="nationalitesData"
                  :onlinesData="onlinesData" :postesData="postesData" :sexesData="sexesData"
                  :sitesData="sitesData"
                  :situationsData="situationsData" :typesData="typesData" :usersData="usersData"
                  :villesData="villesData"
                  :zonesData="zonesData" @close="closeForm"/>

      <CreateAgents v-if="formState == 'Create'" :key="formKey" :actifsData="actifsData"
                    :balisesData="balisesData"
                    :categoriesData="categoriesData" :champsAfficher="champsAfficher" :contratsData="contratsData"
                    :directionsData="directionsData" :echelonsData="echelonsData" :factionsData="factionsData"
                    :fonctionsData="fonctionsData" :gridApi="formGridApi" :matrimonialesData="matrimonialesData"
                    :modalFormId="formId" :nationalitesData="nationalitesData"
                    :onlinesData="onlinesData"
                    :postesData="postesData" :sexesData="sexesData" :sitesData="sitesData"
                    :situationsData="situationsData"
                    :typesData="typesData" :usersData="usersData" :villesData="villesData" :zonesData="zonesData"
                    @close="closeForm"/>

      <CreateImports v-if="formState == 'Import'" :key="formKey" :data="formData" :gridApi="formGridApi"
                     :modalFormId="formId" @close="closeForm"/>

      <Pointageagents v-if="formState == 'Pointages'" :key="formKey" :data="formData"/>

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>


    <div class="col-sm-12">
      <AgGridTable :columnDefs="columnDefs"
                   :extrasData="extrasData" :maxBlocksInCache="maxBlocksInCache"
                   :pagination="pagination"
                   :defaultColumnsOrder="['status_photos','typeseffectifs.libelle','matricule','nom','prenom','num_badge','fonctions.libelle','directions.libelle','zones.libelle','clients.libelle','postes.libelle','sites.libelle']"
                   :paginationPageSize="paginationPageSize" :rowData="rowData" :rowModelType="rowModelType"
                   :url="url"
                   className="ag-theme-alpine"
                   domLayout="autoHeight" rowSelection="multiple" @gridReady="onGridReady">
        <template #header_buttons>
          <div v-if="subDomain != 'sgs'" class="btn btn-primary" @click="openCreate">
            <i class="fa fa-plus"></i>
            Ajouter un agent
          </div>
          <!-- <div class="btn btn-primary" @click="openImport">
              <i class="fa fa-plus"></i>
              Importer des agents a partir d'un fichier excel
          </div> -->
        </template>
      </AgGridTable>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';




import moment from "moment";

export default {
  name: "AgentsView",
  components: {
    DataTable: () => import( "@/components/DataTable.vue"),
    AgGridTable: () => import("@/components/AgGridTable.vue"),
    CreateAgents: () => import("./CreateAgents.vue"),
    EditAgents: () => import("./EditAgents.vue"),
    DataModal: () => import("@/components/DataModal.vue"),
    AgGridBtnClicked: () => import("@/components/AgGridBtnClicked.vue"),
    CustomFiltre: () => import("@/components/CustomFiltre.vue"),
    CreateImports: () => import("../Imports/CreateImports.vue"),
    Pointageagents: () => import("./Pointageagents.vue"),
    CustomSelect:()=>import("@/components/CustomSelect.vue")
},
  data() {
    return {
      importParams: {
        type: "agents",
        allChamps: [
          "nom",
          "prenom",
          "matricule",
          "naissance",
          "embauche",
          "badge",
          "cnamgs",
          "cnss",
          "code nationalite",
          "nationalite",
          "code fonction",
          "fonction",
          "code direction",
          "direction",
          "code echelon",
          "echelon",
          "code categorie",
          "categorie",
          "code contrat",
          "contrat",
        ],
      },
      test: "users",
      formId: "users",
      formState: "",
      formData: {},
      formWidth: "xl",
      formGridApi: {},
      formKey: 0,
      tableKey: 0,
      url: "http://127.0.0.1:8000/api/users-Aggrid1",
      table: "users",
      actifsData: [],
      validationsData: [],
      balisesData: [],
      categoriesData: [],
      contratsData: [],
      directionsget: [],
      zonesget: [],
      directionsData: [],
      echelonsData: [],
      factionsData: [],
      fonctionsData: [],
      matrimonialesData: [],
      nationalitesData: [],
      onlinesData: [],
      postesData: [],
      sexesData: [],
      directionselectionner: [],
      sitesData: [],
      situationsData: [],
      zoneselectionner: [],
      typesData: [],
      usersData: [],
      villesData: [],
      typesget: [],
      typeselectionner: [],
      zonesData: [],
      requette: 19,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowModelType: null,
      pagination: true,
      paginationPageSize: 25,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
      champsAfficher: [
        //LISTE DES CHAMP à MASQUER
      ],
    };
  },

  computed: {
    ...mapGetters('app', ['subDomain', 'domain']),
    $routeData: function () {
      let router = {meta: {}};
      try {
        if (typeof window.routeData != "undefined") {
          router = window.routeData;
        }
      } catch (e) {
      }
      return router;
    },
    taille: function () {
      let result = "col-sm-12";
      if (this.filtre) {
        result = "col-sm-9";
      }
      return result;
    },
    extrasData: function () {

      let params = {}
      params['typeseffectifs.id'] = {values: '', filterType: 'notBlank'}

      this.tableKey++
      return {
        'baseFilter': params,
        zoneselectionner: this.zoneselectionner,
        typeselectionner: this.typeselectionner,
        directionselectionner: this.directionselectionner,
      }

    },
  },
  watch: {
    $route: {
      handler: function (after, before) {
        this.gridApi.setFilterModel(null);
        this.gridApi.refreshServerSide();
        this.getcontrats();
        this.tableKey++;
      },
      deep: true,
    },
  },
  created() {
    this.url = this.axios.defaults.baseURL + "/api/users-Aggrid1"
    // this.url = 'http://127.0.0.1:8000/users-Aggrid1'
    // this.url = "http://127.0.0.1:8000/users-Aggrid1"
    this.formId = this.table + "_" + Date.now();
    this.rowBuffer = 0;
    this.rowModelType = "serverSide";
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;
    console.log('this.zonesget', this.rowData)
// this.champsAfficher = JSON.parse(this.$route.meta.champsHide)

    this.validationsData = [
      "non",
      "oui"
    ],
        this.champsAfficher = [
          //LISTE DES CHAMP à MASQUER POUR sgs
          "emp_code",
          "service",
          "telephone1",
          "nombre_enfant",
          "balise_id",
          "categorie_id",
          "contrat_id",
          "echelon_id",
          "faction_id",
          "matrimoniale_id",
          "poste_id",
          "situation_id",
          "ville_id",
          "zone_id",
          //    "date_naissance",
          "num_cnss",
          "num_cnamgs",
          "nationalite_id",
          "sexe_id",
          "typeseffectif_id"
        ];
  },
  beforeMount() {

    this.columnDefs = [
      {
        field: 'users.id',
        headerName: "",
        suppressCellSelection: true,
        minWidth: 80, maxWidth: 80,
        pinned: "left",
        cellRendererSelector: (params) => {
          return {
            component: "AgGridBtnClicked",
            params: {
              clicked: (field) => {
                this.showForm("Update", field, params.api);
              },
              render: `<div class="btn btn-primary  d-flex justify-content-center align-items-center" style="width:100%;height:100%;color:#fff;border-radius:5px;text-align:center;cursor:pointer">  <i class="fa-solid fa-pen-to-square "></i></div>`,
            },
          };
        },
      },

      {
        field: "nom",
        sortable: true,
        floatingFilter: true,
        filter: this.havefilter("nom"),
        filterParams: {suppressAndOrCondition: true},
        headerName: "nom",
        hide: this.isShow("nom"),
        suppressColumnsToolPanel: this.isShow("nom"),
        valueFormatter: params => {
          let retour = ''
          try {
            return params.data['nom'].toUpperCase()
          } catch (e) {

          }
          return retour
        }
      },

      {
        field: "prenom",
        sortable: true,
        floatingFilter: true,
        filter: this.havefilter("prenom"),
        filterParams: {suppressAndOrCondition: true},
        headerName: "prenom",
        hide: this.isShow("prenom"),
        suppressColumnsToolPanel: this.isShow("prenom"),
        valueFormatter: params => {
          let retour = ''
          try {
            return params.data['prenom'].toUpperCase()
          } catch (e) {

          }
          return retour
        }
      },

      {
        field: "num_badge",
        sortable: true,
        floatingFilter: true,
        filter: this.havefilter("num_badge"),
        filterParams: {suppressAndOrCondition: true},
        headerName: "Carte MIfare",
        hide: this.isShow("num_badge"),
        suppressColumnsToolPanel: this.isShow("num_badge"),
        valueFormatter: params => {
          let retour = ''
          try {
            return params.data['num_badge'].toUpperCase()
          } catch (e) {

          }
          return retour
        }
      },

      {
        headerName: "solde",
        sortable: true,
        field: "cartes.solde",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {suppressAndOrCondition: true},
        join: {
          table: 'cartes',
          champ1: 'users.num_badge',
          champ2: 'cartes.uid_mifare',
          operateur: '=',
        }
      },
      {
        hide: true,
        suppressColumnsToolPanel: true,

        headerName: 'type',
        field: 'typeseffectif_id',
        valueFormatter: params => {
          let retour = ''
          try {
            return params.data['typeseffectif']['Selectlabel']
          } catch (e) {

          }
          return retour
        },
        filter: "FiltreEntete",
        filterParams: {
          url: this.axios.defaults.baseURL + '/api/typeseffectifs-Aggrid1',
          columnDefs: [
            {
              field: "",
              sortable: true,
              filter: "agTextColumnFilter",
              filterParams: {suppressAndOrCondition: true},
              headerName: "",
              cellStyle: {fontSize: '11px'},
              valueFormatter: (params) => {
                let retour = "";
                try {
                  return `${params.data["Selectlabel"]}`;
                } catch (e) {
                }
                return retour;
              },
            },
          ],
          filterFields: ['libelle'],
        },
        join: {
          table: 'typeseffectifs',
          champ1: 'users.typeseffectif_id',
          champ2: 'typeseffectifs.id',
          operateur: '=',
        }
      },


      {
        headerName: "nationalite",
        sortable: true,
        field: "nationalites.libelle",
        floatingFilter: true,
        filterParams: {suppressAndOrCondition: true},
        join: {
          table: 'nationalites',
          champ1: 'users.nationalite_id',
          champ2: 'nationalites.id',
          operateur: '=',
        }
      },


      {
        headerName: "types clients",
        sortable: true,
        field: "typeseffectifs.libelle",

        floatingFilter: true,

        filter: this.havefilter("typeseffectif"),
        filterParams: {suppressAndOrCondition: true},
      },
      {
        headerName: "typeseffectif",
        sortable: true,
        field: "typeseffectifs.id",
        hide: true,
        suppressColumnsToolPanel: true,

      },

      {
        field: "users.created_at",
        sortable: true,
        filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
        headerName: 'Créer le',
        valueFormatter: params => {
          let retour = params.value
          try {
            if (retour) {
              retour = moment(params.value).format('DD/MM/YYYY à HH:mm')

            } else {
              retour = 'Date inconnue'
            }
          } catch (e) {

          }
          return retour
        }
      },
    ];

    // }
  },
  mounted() {


    if (this.requette > 0) {
      // this.$store.commit('setIsLoading', true)
    }
    this.typesget = this.$route.meta.typesGet
    console.log('voila le windows', window)
    window.Echo
        .channel('user-channel')
        .listen('SendMessage', (data) => {
          console.log('nouvelle evenements')
        });
    this.directionsget = this.$route.meta.directionsGet
    // this.zonesget = this.$route.meta.champsHide
// console.log('this.zonesget',this.zonesget);

    // this.getactifs();
    // this.getbalises();
    // this.getcategories();
    // this.getcontrats();
    // this.getdirections();
    // this.getechelons();
    // this.getfactions();
    // this.getfonctions();
    // this.getmatrimoniales();
    // this.getnationalites();
    // this.getonlines();
    // this.getpostes();
    // this.getsexes();
    // this.getsites();
    // this.getusers();
    // this.getsituations();
    // this.gettypes();
    // this.getvilles();
    // this.getzones();
    console.log('this.champsAfficher =', this.champsAfficher)

  },
  methods: {
    isShow(fieldName) {
      // METHODE UTILISER DANS (HIDE) POUR PERMETTRE DE MASQUER LES CHAMPS MIS DANS LE TABLEAU champsAfficher
      return this.champsAfficher.includes(fieldName); // si le champ existe return prend la valeur *true*
    },
    havefilter(fieldName) {
      // METHODE UTILISER DANS (HIDE) POUR PERMETTRE DE MASQUER LES CHAMPS MIS DANS LE TABLEAU champsAfficher
      if (this.champsAfficher.includes(fieldName)) {
        return null
      } else {
        return "agTextColumnFilter"
      }
    },
    havecustomfilter(fieldName) {
      // METHODE UTILISER DANS (HIDE) POUR PERMETTRE DE MASQUER LES CHAMPS MIS DANS LE TABLEAU champsAfficher
      if (this.champsAfficher.includes(fieldName)) {
        return null
      } else {
        return "CustomFiltre"
      }
    },
    openCreate() {
      this.showForm("Create", {}, this.gridApi);
    },
    openImport() {
      this.showForm("Import", this.importParams, this.gridApi, "lg");
    },
    closeForm() {
      try {
        this.gridApi.refreshServerSide()
      } catch (e) {

      }
    },
    showForm(type, data, gridApi, width = "lg") {
      this.formKey++;
      this.formWidth = width;
      this.formState = type;
      this.formData = data;
      this.formGridApi = gridApi;
      this.$bvModal.show(this.formId);
    },
    onGridReady(params) {
      console.log("on demarre", params);
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.isLoading = false;
    },
    getactifs() {
      this.axios
          .get("/api/actifs")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.actifsData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getbalises() {
      this.axios
          .get("/api/balises")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.balisesData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getcategories() {
      this.axios
          .get("/api/categories")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.categoriesData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getcontrats() {
      if (this.$route.meta.isOne) {
        // console.log('one')
        this.axios
            .get("/api/contrats/typeView/one")
            .then((response) => {
              this.requette--;
              if (this.requette == 0) {
                // // this.$store.commit('setIsLoading', false)
              }
              this.contratsData = response.data;
            })
            .catch((error) => {
              console.log(error.response.data);
              // // this.$store.commit('setIsLoading', false)
              this.$toast.error(
                  "Erreur survenue lors de la récuperation"
              );
            });
      } else {
        // console.log('!one')
        this.axios
            .get("/api/contrats/typeView/sgs")
            .then((response) => {
              this.requette--;
              if (this.requette == 0) {
                // // this.$store.commit('setIsLoading', false)
              }
              this.contratsData = response.data;
            })
            .catch((error) => {
              console.log(error.response.data);
              // // this.$store.commit('setIsLoading', false)
              this.$toast.error(
                  "Erreur survenue lors de la récuperation"
              );
            });
      }
    },

    getdirections() {
      this.axios
          .get("/api/directions")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.directionsData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getechelons() {
      this.axios
          .get("/api/echelons")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.echelonsData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getfactions() {
      this.axios
          .get("/api/factions")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.factionsData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getfonctions() {
      this.axios
          .get("/api/fonctions")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.fonctionsData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getmatrimoniales() {
      this.axios
          .get("/api/matrimoniales")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.matrimonialesData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getnationalites() {
      this.axios
          .get("/api/nationalites")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.nationalitesData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getonlines() {
      this.axios
          .get("/api/onlines")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.onlinesData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getpostes() {
      this.axios
          .get("/api/postes")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.postesData = response.data;
            // console.log('yannfiltreP=>', response.data)
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getsexes() {
      this.axios
          .get("/api/sexes")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.sexesData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    // getsites() {
    //   this.axios.get('/api/sites').then((response) => {
    //     this.requette--
    //     if (this.requette == 0) {
    //       // // this.$store.commit('setIsLoading', false)
    //     }
    //     this.sitesData = response.data

    //   }).catch(error => {
    //     console.log(error.response.data)
    //     // // this.$store.commit('setIsLoading', false)
    //     this.$toast.error('Erreur survenue lors de la récuperation')
    //   })
    // },

    getsites() {
      this.axios
          .get("/api/sites")
          .then((response) => {
            // console.log('yannfiltre=>',response.data)
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            // console.log('yannfiltre=>', response.data)
            this.sitesData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getsituations() {
      this.axios
          .get("/api/situations")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.situationsData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    gettypes() {
      this.axios
          .get("/api/types")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.typesData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getusers() {
      this.axios
          .get("/api/users?filter[actif_id]=1&filter[type]=2")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            // this.usersData = response.data
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getvilles() {
      this.axios
          .get("/api/villes")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.villesData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },

    getzones() {
      this.axios
          .get("/api/zones")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // this.$store.commit('setIsLoading', false)
            }
            this.zonesData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },
    directionsselect(direction) {

      if (this.directionselectionner.includes(direction)) {
        const index = this.directionselectionner.indexOf(direction);
        if (index !== -1) {
          this.directionselectionner.splice(index, 1);
        }
      } else {
        this.directionselectionner.push(direction);
      }

      this.extrasData1.directionselectionner = this.directionselectionner

    },
    zoneselect(zone) {
      //   this.actualZone = zone;
      if (this.zoneselectionner.includes(zone)) {
        // Zone is already selected, so we want to deselect it
        const index = this.zoneselectionner.indexOf(zone);
        if (index !== -1) {
          this.zoneselectionner.splice(index, 1); // Remove the zone from the array
        }
      } else {
        // Zone is not selected, so we want to select it
        this.zoneselectionner.push(zone);
      }
      this.extrasData1.zoneselectionner = this.zoneselectionner

      // console.log('this.zoneselectionner', this.zoneselectionner)
    },
    typeselect(type) {
      if (this.typeselectionner.includes(type)) {
        // type is already selected, so we want to deselect it
        const index = this.typeselectionner.indexOf(type);
        if (index !== -1) {
          this.typeselectionner.splice(index, 1); // Remove the zone from the array
        }
      } else {
        // type is not selected, so we want to select it
        this.typeselectionner.push(type);
      }

    },
  },
};
</script>

<style>
.ag-root-wrapper {
  border-radius: 5px
}

.childBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 95%;
  margin: 10px auto;
}

.newButton {
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: 15px;
  right: 30px;
  border-radius: 5px;
  padding: 10px;
  background: #0004ff;
  color: #fff;
}
</style>
